div {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #b9b4b4;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  background-color: #333;
  color: #fff;
  padding: 0 20px;
}

/* Logo styles */
.h1 {

  color: #6e6b6b;
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  padding: 10px 10px 10px 10px;
}

/* Menu styles */
.menu {
  display: flex;
  align-items: center;
  background-color: #333;
  color: #fff;
}
.menu li {
  background-color: #333;
  color: #fff;
  list-style: none;
  margin-right: 60px;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
}

/* Explore and register styles */
.user-actions {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  text-transform: uppercase;
  align-items: center;
}


.content {
  padding: 20px;
  text-align: center;
}
.description {
  text-align: left;
  padding-left: 20px;
}
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #f5f5f5;
  padding: 10px;
  text-align: center;
}
#dropzone {
  width: 300px;
  height: 200px;
  border: 2px dashed #000000;
  padding: 20px;
  text-align: center;
  font-size: 20px;
  color: #000000;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 70px;
  background-color: #f5f5f5;
}

.dragover {
  border-color: #333;
}
#file-name {
  margin-top: 10px;
}

.input-holder {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
  height: 70px;
}

.form-control {
  border: none;
  border-radius: 0;
  box-shadow: none;
  width: 300px;
}

.input-holder label {
  font-size: 18px;
  margin-bottom: 10px;
  align-items: center;
}

.input-holder input[type="text"] {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}



.btn {
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}

.btn-primary {
  background-color: #007bff;
  color: #fff;
}

.btn-primary:hover {
  background-color: #0062cc;
  color: #fff;
}

.content-fluid {
  padding: 20px;
}

.history-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.history-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.history-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f2f2f2;
}

.history-name {
  margin-left: auto;
  flex: 1;
}

.history-date {
  flex: 0.5;
}

.history-download {
  background-color: #007bff;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  text-decoration: none;
}

.login-title {
  font-size: 24px;
  margin-bottom: 20px;
}
.login-form {
  width: 50%;
  margin: 0 auto;
}
.form-group {
  margin-bottom: 20px;
}
label {
  display: block;
  margin-bottom: 5px;
}
input[type=text], input[type=password], input[type=checkbox] {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
}
input[type=checkbox] {
  margin-right: 5px;
}
.login-button {
  background-color: #4CAF50;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  font-size: 16px;
  cursor: pointer;
}
.login-button:hover {
  background-color: #3e8e41;
}
.register-link {
  margin-top: 20px;
  text-align: center;
}
.register-link a {
  color: #4CAF50;
  text-decoration: none;
}
.register-link a:hover {
  text-decoration: underline;
}

* {box-sizing: border-box}

/* Add padding to containers */
.container {
  padding: 16px;
}

/* Full-width input fields */
input[type=text], input[type=password] {
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px 0;
  display: inline-block;
  border: none;
  background: #f1f1f1;
}

input[type=text]:focus, input[type=password]:focus {
  background-color: #ddd;
  outline: none;
}

/* Overwrite default styles of hr */
hr {
  border: 1px solid #f1f1f1;
  margin-bottom: 25px;
}

/* Set a style for the submit/register button */
.registerbtn {
  background-color: #04AA6D;
  color: white;
  padding: 16px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
  opacity: 0.9;
}

.registerbtn:hover {
  opacity:1;
}

/* Add a blue text color to links */
a {
  color: dodgerblue;
}

/* Set a grey background color and center the text of the "sign in" section */
.signin {
  background-color: #f1f1f1;
  text-align: center;
}

.qr-code-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px;
  background-color: #f7f7f7;
}

.qr-code-container img {
  margin-bottom: 20px;
}

.btn-download {
  margin-top: auto;
}

.text-holder {
  padding: 20px;
  background-color: #fff;
  height: 100%;
}

.text-holder h2 {
  margin-bottom: 20px;
}

.login-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 250px;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.input-group {
  margin-bottom: 10px;
}

.input-group-text {
  background-color: #007bff;
  border: none;
  color: white;
}


.btn {
  width: 100%;
  border-radius: 5px;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.btn-secondary {
  background-color: #e9ecef;
  border-color: #e9ecef;
  color: #6c757d;
}

.button-group {
  display: flex;
  justify-content: space-between;
}
.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
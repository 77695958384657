.form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.form-row {
    display: flex;
    align-items: center;
}

#dropzone {
    border: 2px dashed #ccc;
    padding: 20px;
    text-align: center;
    width: 300px;
    height: 200px;
}

#dropzone p {
    margin: 0;
}

#fileInput {
    display: none;
}

#file-name {
    margin-top: 10px;
    font-weight: bold;
}

.input-holder {
    margin-left: 20px;
}

#text-area {
    height: 100px;
    width: 300px;
}

.btn-primary {
    margin-top: 10px;
}

.nav-link {
    display: inline-block;
    margin-top: 10px;
}
